import { useState } from "react";
import { useDiscordLogin } from "react-discord-login";

function App() {
  const FRIENDS = [
    "alice._.he",
    "calvin14",
    "cinadia",
    "w.oof",
    "universityofbc",
    "ehussain",
    "gigimk_23",
    "unseriouspanda",
    "joelexia",
    "justindhillon",
    "lavendermochi",
    "michellin",
    "itzmirl",
    "mowmow.",
    "nyctopurple",
    "rararara10",
    "ramitk",
    "renizen_",
    ".ryankim",
    "isapenguin9",
    "jazz355",
    "swaff.ie",
    "_kliu",
    "watermelon3918",
    "youssefm",
    "aiden.k",
    ".pcapng",
    "pepph",
    "hgjnnf",
    ".jinying",
    "notgourd",
    "thithi24",
    "gervas.io",
    "neo_from_the_matrix",
    "radioactivehydra",
    "jiramide",
    "kewbish",
    ".darryl.",
    "ikamino",
    "skyleapa",
    "cooqie",
    "teddy.yy",
    "ryyanwang",
    "poliwhirl555",
    "2pctmilk",
    "techleno",
    "l0bsters",
    "auwhry",
    "cuppp",
    "oats22",
    "edgycedgie",
    "dylanzyl",
    "citadelsimp25",
    ".shuai",
    "isabellahan ",
    "jessie.shang",
    "blue.panda03",
    "michaelfromyeg",
    "patrickhys",
    "pattytanch",
    "rachel26",
    "renata_91460",
    "ridhwanlaibadmos",
    "shakingthemyhead",
    "scarlet13_",
    "devon.m",
    "omentic",
  ];

  const discordLoginParams = {
    clientId: "1233831631148810261",
    redirectUri: `${window.location.href}`,
    responseType: "token", // or 'code'
    scopes: ["identify"],
    onSuccess: (response) => {
      if (FRIENDS.includes(response.user.username)) {
        setUsername(response.user.username);
        setUserId(response.user.id);
        setAvatarId(response.user.avatar);
        setIsAuthed(true);
      } else {
        setIsAuthed(false);
      }
    },
    onFailure: (error) => {
      setIsAuthed(false);
    },
  };

  const { buildUrl, isLoading } = useDiscordLogin(discordLoginParams);
  const [isAuthed, setIsAuthed] = useState(null);
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [avatarId, setAvatarId] = useState("");

  return (
    <div style={{ maxWidth: "100%", width: "100%" }}>
      {isAuthed === null ? (
        <>
          <h1>BeSquare</h1>
          <p>
            A little site for coordinating hangouts with friends and sharing
            plans[^1]. For now, IRLs (including CSSS-adjacent) in Van only +
            plans for before May 4th!
          </p>
          <p>
            <em>
              Sign in with Discord to continue, just so I can verify your
              username:
            </em>
          </p>
          <button
            onClick={() => (window.location.href = buildUrl())}
            disabled={isLoading}
          >
            Sign in with Discord
          </button>
          <p>
            [^1]: something something be there or be square it was funnier in my
            head ok
          </p>
        </>
      ) : null}
      {isAuthed === false ? (
        <>
          <p>
            Sorry, your username wasn't on the list of expected users. Check in
            with me (@kewbish), I probably forgot to add you! If you're
            CSSS-adjacent or know me personally you should probably be on this
            list.
          </p>
        </>
      ) : null}
      {isAuthed === true ? (
        <>
          <h1>Welcome to BeSquare!</h1>
          <p>
            Below are some ideas! I want to make the most of my last week in Van
            :( I'll add dates // ppl going to this list as plans go
          </p>
          <details open>
            <summary>[Thursday, May 2nd] Cafe Hopping</summary>
            <p>DT cafe hopping except we actually hop this time!</p>
            <p>
              <a href="https://maps.app.goo.gl/MNU53r3s9yKxxkmEA">
                list of places
              </a>
              , general itinerary:
            </p>
            <ul>
              <li>meet at waterfront ~12PM</li>
              <li>
                gastown until ~2PMish (options include monaco cafe, nemesis
                coffee, saunter coffee)
              </li>
              <li>
                SAP talk from 2PM-3:30PM, people not going (including me) can
                hang around the library or go to more cafes
              </li>
              <li>
                northwest robson until ~6PMish, there's a cluster of cafes
                around robson and thurlow // or we can go look around stores
              </li>
              <li>
                dinner options: big way hotpot, maruhachi ramen, saku, one of
                the other restaurants at the end of robson. if no one has strong
                opinions we will go for big way because (1) it will probably be
                easier to accomodate a larger group (2) reservations are hard to
                make without knowing exactly who's coming (3) there will
                probably be a group gratuity otherwise (4) ryan kim seems to
                like it
              </li>
            </ul>
            <p>people coming: CSSS-adjacent</p>
            <p>
              feel free to join in later asw, please text me (+1-604-339-1333)
              and I'll let you know where ppl are
            </p>
            <button
              style={{
                border: "3px solid #b3a0de",
                color: "var(--text-bright)",
              }}
              onClick={async () => {
                await fetch(
                  "https://discord.com/api/webhooks/1233850912888459305/UePjAOUHNFt8MNhkFPf-1EuBfYXXibkB4FQ830HQtATMgEU4eZlVsw4dBy2OWPHAl4WU",
                  {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      content: "",
                      tts: false,
                      embeds: [
                        {
                          description: `is coming for cafe hopping`,
                          color: 11772126,
                          fields: [],
                          author: {
                            name: username,
                            icon_url: `https://cdn.discordapp.com/avatars/${userId}/${avatarId}.png`,
                          },
                        },
                      ],
                      components: [],
                      actions: {},
                      username: "BeSquare",
                    }),
                  }
                );
                alert("Thank you!");
              }}
            >
              please click this button one (1) time if you're coming so I can
              get a sense of numbers
            </button>
          </details>
          <details>
            <summary>
              <s>North Van</s>
            </summary>
            <p>
              ok I realized most of you already took the seabus // explored
              north van for the gala but hey
            </p>
            <ul>
              <li>
                there's a free <a href="https://thepolygon.ca/">art gallery</a>{" "}
                with a cute photobooth
              </li>
              <li>
                <a href="https://www.nemesis.coffee/">nemesis coffee</a> has a
                location in north van and it looks v cozy
              </li>
              <li>
                the <a href="https://lonsdalequay.com/">quay market</a> is fun
                to explore
              </li>
              <li>
                kinda want to walk along the parks there's a maritime college
                somewhere and rows of floating houses
              </li>
            </ul>
          </details>
          <details>
            <summary>
              <s>Richmond ± Nature</s>
            </summary>
            <p>requires walking stamina and good weather</p>
            <ul>
              <li>
                someone recommended{" "}
                <a href="https://www.tsujiri.ca/pages/tsujiri-richmond-menu">
                  tsujiri
                </a>{" "}
                for bougie matcha desserts
              </li>
              <li>
                you can walk all the way along the river from where we had the
                gala last year to the oval // terra nova (~1.5h walk maybe?)
              </li>
              <li>
                we can probably also walk all the way to steveston but that's
                ~3h and idk if y'all have the stamina for that
              </li>
              <li>
                we could also just go to ikea on thursday for $4.50 meatballs...
              </li>
            </ul>
          </details>
          <details>
            <summary>
              <s>Getting Eaten by Coyotes (or: Stanley Park)</s>
            </summary>
            <p>my mother fears I will be eaten by coyotes if I go alone</p>
            <ul>
              <li>
                there are bike rentals and tandem bikes and you can go around
                the seawall in a little more than an hour
              </li>
              <li>
                we can also walk around the whole seawall (~2-3h) but again idk
                if y'all have the stamina for that
              </li>
            </ul>
          </details>

          <p>
            lmk if you have specific dates you're free // other ideas you're
            interested in!
          </p>
          {/*<p>
            if you open the dialog and select an option it'll send me a msg and
            I can coordinate (reopen multiple times to pick multiple things, or
            pick 'any')
          </p>
          <button
            onClick={() => {
              document.getElementById("dialog").showModal();
            }}
          >
            Choose options
          </button>
          <dialog
            id="dialog"
            onClose={(e) => {
              if (e.target.returnValue === "cancel") {
                return;
              } else {
                // yes blah blah not very secure don't spam me thanks
                fetch(
                  "https://discord.com/api/webhooks/1233850912888459305/UePjAOUHNFt8MNhkFPf-1EuBfYXXibkB4FQ830HQtATMgEU4eZlVsw4dBy2OWPHAl4WU",
                  {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      content: "",
                      tts: false,
                      embeds: [
                        {
                          description: `down for ${e.target.returnValue}`,
                          color: 11772126,
                          fields: [],
                          author: {
                            name: username,
                            icon_url: `https://cdn.discordapp.com/avatars/${userId}/${avatarId}.png`,
                          },
                        },
                      ],
                      components: [],
                      actions: {},
                      username: "BeSquare",
                    }),
                  }
                );
              }
            }}
          >
            <header>select things you are down for</header>{" "}
            <form method="dialog">
              <menu style={{ marginBottom: 0 }}>
                <button value="northvan">North Van</button>{" "}
                <button value="richmond">Richmond</button>{" "}
                <button value="stanleypark">Stanley Park</button>
                <button value="cafehopping">Cafe Hopping</button>
                <button value="any">Any</button>
              </menu>{" "}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button value="cancel" formMethod="dialog">
                  close
                </button>
              </div>
            </form>{" "}
          </dialog>*/}
          <p>🫶 — Emilie</p>
          <br />
        </>
      ) : null}
    </div>
  );
}

export default App;
